@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.home__container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home__container>* {
  margin-bottom: 10px;
}

.home__header {
  margin-bottom: 30px;
}

.username__input {
  padding: 10px;
  width: 50%;
}

.home__cta {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #607eaa;
  color: #f9f5eb;
  outline: none;
  border: none;
  border-radius: 5px;
}

.chat {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.chat__sidebar {
  height: 100%;
  background-color: #f9f5eb;
  flex: 0.2;
  padding: 20px;
  border-right: 1px solid #fdfdfd;
}

.chat__main {
  height: 100%;
  flex: 0.8;
}

.chat__header {
  margin: 30px 0 20px 0;
}

.chat__users>* {
  margin-bottom: 10px;
  color: #607eaa;
  font-size: 14px;
}

.online__users>* {
  margin-bottom: 10px;
  color: rgb(109, 102, 238);
  font-style: italic;
}

.chat__mainHeader {
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  background-color: #ebedf9;
}

.leaveChat__btn {
  padding: 10px;
  width: 150px;
  border: none;
  outline: none;
  background-color: #2d5bd1;
  cursor: pointer;
  color: #d6d2ea;
}

.message__container {
  width: 100%;
  height: 78vh;
  background-color: #fff;
  padding: 20px;
  overflow-y: scroll;
}

.message__container>* {
  margin-bottom: 10px;
}

.chat__footer {
  padding: 10px;
  background-color: #ebecf9;
  height: 15vh;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message {
  width: 80%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #dddddd;
  outline: none;
  padding: 15px;
  flex: 1 1;
  background-color: var(--chatfooter_textarea_bg_color);
  color: var(--chatfooter_textarea_color);
  resize: none;
}

.sendBtn {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: var(--chatfooter_button_color);
  padding: 10px;
  border: none;
  outline: none;
  color: var(--chatfooter_button_icon_color);
  cursor: pointer;
  margin-left: 2%;
}

/* .sendBtn:hover {
  background-color: rgb(129, 139, 201);
} */

.message__recipient {
  background-color: #c3c2f5;
  width: 300px;
  padding: 8px;
  border-radius: 10px;
  font-size: 12px;
}

.message__sender {
  background-color: rgb(194, 205, 243);
  max-width: 300px;
  padding: 8px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 12px;
}

.message__chats>p {
  font-size: 13px;
}

.sender__name {
  text-align: right;
}

.message__status {
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}

/* WhatsApp UI */

:root {
  --text_color: #ffffff;
  --bg_color: #000000;
  --link_color: #6495ed;
  --incoming_bubble_color: #262d31;
  --incoming_text_color: #ffffff;
  --incoming_disabled_bubble_color: #262d31;
  --incoming_disabled_text_color: #ffffff;
  --outgoing_bubble_color: #056162;
  --outgoing_text_color: #ffffff;
  --quickreplies_bg_color: #ffffff;
  --quickreplies_text_color: #000000;
  --quickreplies_border_color: #ffffff;
  --quickreplies_disabled_bg_color: #000000;
  --quickreplies_disabled_text_color: #ffffff;
  --quickreplies_disabled_border_color: #000000;
  --chatfooter_button_color: #001380;
  --chatfooter_button_icon_color: #616275;
  --chatfooter_textarea_bg_color: #33383b;
  --chatfooter_textarea_color: #ffffff;
  --chatfooter_bg_color: #33383b;
  --header_bg_color: #33383b;
  --header_text_color: #ffffff;
  --scrolldown_bg_color: #1a1b20;
  --scrolldown_icon_color: #616275;
  --context_bg_color: #1a1b20;
  --context_color: #ffffff;
  --highlight_bubble_bg: #396984;
  --typing_icon_color: #ffffff; 
}

p {
  color: var(--text_color);
}

.chat__footer .btn-icon {
  color: var(--chatfooter_button_icon_color);
  display: flex;
  justify-content: center;
}

.chat__main {
  background: var(--bg_color) !important;
  color: rgba(241, 241, 242, .95) !important;
}

.message__container {
  background-color: var(--bg_color) !important;
}

.message__sender {
  background: var(--outgoing_bubble_color) !important;
  position: relative;
  border-top-right-radius: inherit;
}

.message__sender::before {
  right: -8px;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border-color: #dbf8c6 transparent transparent #dbf8c6;
  border-style: solid;
  border-width: 6px;
  border-top-color: var(--outgoing_bubble_color);
  border-left-color: var(--outgoing_bubble_color);
}

.message__recipient {
  background: var(--incoming_bubble_color) !important;
  position: relative;
  border-top-left-radius: inherit;
}

.message__recipient::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: -8px;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 6px;
  border-top-color: var(--incoming_bubble_color);
  border-right-color: var(--incoming_bubble_color);
}

.chat__mainHeader {
  background: var(--header_bg_color) !important;
  color: var(--header_text_color);
}

.chat__mainHeader p {
  color: var(--header_text_color) !important;
}

.chat__mainheader__name {
  color: var(--header_text_color) !important;
}

.chat__footer {
  background: var(--chatfooter_bg_color) !important;
}

.dark-theme ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, .16);
}

.bubble {
  /* width: fit-content !important; */
  width: 70% !important;
}

.timestamp {
  color: var(--timestamp_color);
  font-size: 0.55rem;
  ;
  font-weight: 500;
  text-align: end;
}

.message__content {
  margin-bottom: 25px;
  word-wrap: break-word;
}

.content__image {
  width: 350px;
  margin-bottom: 5px;
}

.message__recipient.message__recipient_disabled {
  background: var(--incoming_disabled_bubble_color) !important;
}

.message__recipient.message__recipient_disabled::before {
  border-top-color: var(--incoming_disabled_bubble_color);
  border-right-color: var(--incoming_disabled_bubble_color);
}

.quick_reply_button {
  background-color: var(--quickreplies_bg_color);
  color: var(--quickreplies_text_color);
  border: 2px solid var(--quickreplies_border_color);
  text-align: center;
  padding: 5px;
  border-radius: 11px;
  cursor: pointer;
  margin: 7px;
  margin-bottom: 5px;
}

.message__context {
  width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 1% !important;
  padding: 5px;
  background-color: var(--context_bg_color);
  border-radius: 5px;
  cursor: pointer;
  word-wrap: break-word !important;
}

.highlight .message__recipient {
  background-color: var(--highlight_bubble_bg) !important;
}

.highlight .message__recipient::before {
  border-top-color: var(--highlight_bubble_bg);
  border-right-color: var(--highlight_bubble_bg);
}

.chat__scroll-btn {
  position: absolute;
  right: 6%;
  bottom: 20%;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: var(--scrolldown_icon_color);
  background: var(--scrolldown_bg_color);
  border-style: none;
  z-index: 999;
}

.__react_modal_image__modal_content img {
  padding: 20px;
}

.message__chats a {
  color: var(--link_color);
}

.list__description {
  font-size: x-small !important;
  color: var(--text_color)
}

.hide {
  display: none !important;
}

.agent__placeholder__value {
  display: none;
}

.dot {
  background-color: var(--typing_icon_color);
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 4px;
  animation: typing-indicator-animation 1s infinite;
}

.message__sender > p {
  color: var(--outgoing_text_color);
}

.message__recipient > p {
  color: var(--incoming_text_color);
}

.quick_reply_button > p {
  color: var(--quickreplies_text_color);
}

@keyframes typing-indicator-animation {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.7);
  }

  60% {
    transform: scale(0.5);
  }
}

.profile-container {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

.profile-picture {
  height: 22px;
  margin-right: 5px;
}

.profile-name {
  font-size: 15px;
  font-weight: 500;
  color: var(--header_text_color) !important;
}

.dotbubble {
  width: fit-content !important;
}

.message__context p {
  color: var(--context_color);
}

.message__container > :nth-child(n - 2) {
  margin-bottom: 40px;
}

.message__preview p {
  margin-left: 3px;
}

.message__preview_link {
  text-decoration-line: none !important;
}

.message__preview {
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 5px !important;
  margin-bottom: 6px !important;
}

.message__preview .placeholder_image {
  height: 42px;
  width: 42px;
  flex-shrink: 0;
}

.uploaded_media_type path {
  fill: var(--context_color);
}

.bubble video {
  width: 100%;
  height: auto; 
}

.message_sticky_alert {
  bottom: 0;
  position: fixed;
  width: 100%;
  background: var(--chatclosed_bg_color);
  padding: 15px 10px 15px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.message_sticky_alert .alert_text{
  font-size: 13px;
}

.sticky_alert_has_footer {
  bottom: 15vh !important;
}

.message_sticky_alert p {
  color: var(--chatclosed_text_color);
  font-weight: 500;
}

.message_sticky_alert span {
  color: var(--chatclosed_link_text_color);
  background-color: var(--chatclosed_link_bg_color);
  cursor: pointer;
  font-weight: 400;
}

.message__alert {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message__alert .alert_content {
  width: fit-content;
  background-color: var(--chatalert_bg_color);
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
}

.message__alert .alert_text {
  text-align: center;
  font-size: 12px;
  color: var(--chatalert_text_color);
}

.preview_modal_container .preview_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--header_text_color);
  background-color: var(--header_bg_color);
  padding: 20px;
  height: 8vh;
  position: relative;
}

.preview_modal_header p {
  width: 70%;
}

.preview_modal_container p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  color: var(--header_text_color);
}

.preview_modal_media_container {
  position: absolute;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  background-color: #222C35;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.preview_black_background {
  background-color: #000;
}

.preview_modal_container .preview_modal_buttons {
  padding: 0px 15px 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
}

.preview_modal_buttons .preview_modal_button {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  vertical-align: bottom;
  white-space: nowrap;
  border: none;
  outline: none;
  cursor: pointer;
}

.preview_modal_buttons .preview_modal_cancel_button {
  height: 40px;
  line-height: 40px;
  background-color: var(--chatfooter_button_color);
  color: var(--chatfooter_button_icon_color);
  padding: 0px 20px;
  border-radius: 999em;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
}

.preview_modal_buttons .preview_modal_send_button {
  font-size: 18px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  padding: 10px;
  margin-left: 5px;
  background-color: var(--header_bg_color);
  color: var(--header_text_color);
}

.preview_modal_media_container .preview_modal_media {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.preview_modal_media img, .preview_modal_media video {
  width: 100%;
  height: auto;
  max-height: 70vh;
}

.preview_modal_media .preview_modal_media_meta p {
  font-size: 14px;
  font-weight: 400;
}

.preview_modal_media_meta .preview_modal_media_meta_file {
  white-space: unset;
  text-overflow: unset;
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
}

.preview_modal_media_meta .preview_modal_media_meta_size {
  margin-top: 10px;
  color: #778899;
  font-size: 15px;
}

.loader_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px;
}

.loader_content svg{
  stroke: var(--outgoing_text_color);
}

/* Overwrite React-Alert CSS */
.status-alerts-wrapper {
  top: 5% !important;
}

.status-alert__text {
  font-size: 13px !important;
}

.status-alert {
  max-width: 500px !important;
}

.status-alert__icon-holder {
  padding-top: 12px !important;
}

/* Overwrite React-Modal-Image CSS */
.__react_modal_image__caption {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.__react_modal_image__modal_content svg {
  z-index: 1;
}

@media (max-width:768px) {
  .__react_modal_image__caption {
    width: 60%;
  }
}

.message__preview p {
  width: 80%;
}

.quick_reply_button.quick_reply_button_disabled {
  background-color: var(--quickreplies_disabled_bg_color);
  color: var(--quickreplies_disabled_text_color);
  border: 2px solid var(--quickreplies_disabled_border_color);
  cursor: not-allowed;
}

.quick_reply_button.quick_reply_button_disabled p {
  color: var(--quickreplies_disabled_text_color);
}

.message__recipient_disabled > p {
  color: var(--incoming_disabled_text_color) !important;
}